import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function InstagramIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M12,2.162c3.204,0,3.584,0.012,4.849,0.07c1.17,0.053,1.805,0.249,2.228,0.413 c0.56,0.218,0.96,0.478,1.38,0.898c0.42,0.42,0.68,0.82,0.898,1.38c0.164,0.423,0.36,1.058,0.413,2.228 c0.058,1.265,0.07,1.645,0.07,4.849s-0.012,3.584-0.07,4.849c-0.053,1.17-0.249,1.805-0.413,2.228 c-0.218,0.56-0.478,0.96-0.898,1.38c-0.42,0.42-0.82,0.68-1.38,0.898c-0.423,0.164-1.058,0.36-2.228,0.413 c-1.265,0.058-1.645,0.07-4.849,0.07s-3.584-0.012-4.849-0.07c-1.17-0.053-1.805-0.249-2.228-0.413 c-0.56-0.218-0.96-0.478-1.38-0.898c-0.42-0.42-0.68-0.82-0.898-1.38c-0.164-0.423-0.36-1.058-0.413-2.228 c-0.058-1.265-0.07-1.645-0.07-4.849s0.012-3.584,0.07-4.849c0.053-1.17,0.249-1.805,0.413-2.228c0.218-0.56,0.478-0.96,0.898-1.38 c0.42-0.42,0.82-0.68,1.38-0.898c0.423-0.164,1.058-0.36,2.228-0.413C8.416,2.174,8.796,2.162,12,2.162 M12,0 C8.741,0,8.332,0.014,7.053,0.072C5.775,0.131,4.903,0.333,4.14,0.63C3.351,0.937,2.681,1.347,2.014,2.014S0.937,3.35,0.63,4.14 C0.333,4.903,0.131,5.775,0.072,7.052C0.014,8.332,0,8.741,0,12s0.014,3.668,0.072,4.948c0.058,1.277,0.261,2.15,0.558,2.913 c0.307,0.789,0.717,1.458,1.384,2.125c0.667,0.667,1.336,1.077,2.125,1.384c0.763,0.297,1.636,0.499,2.913,0.558 C8.332,23.986,8.741,24,12,24s3.668-0.014,4.948-0.072c1.277-0.058,2.15-0.261,2.913-0.558c0.789-0.307,1.458-0.717,2.125-1.384 c0.667-0.667,1.077-1.336,1.384-2.125c0.297-0.763,0.499-1.636,0.558-2.913C23.986,15.668,24,15.259,24,12 s-0.014-3.668-0.072-4.948c-0.058-1.277-0.261-2.15-0.558-2.913c-0.307-0.789-0.717-1.458-1.384-2.125 C21.319,1.347,20.65,0.937,19.86,0.63c-0.763-0.297-1.636-0.499-2.913-0.558C15.668,0.014,15.259,0,12,0L12,0z" />
            <path d="M12,5.838c-3.403,0-6.162,2.759-6.162,6.162S8.597,18.162,12,18.162s6.162-2.759,6.162-6.162 S15.403,5.838,12,5.838z M12,16c-2.209,0-4-1.791-4-4s1.791-4,4-4c2.209,0,4,1.791,4,4S14.209,16,12,16z" />
            <circle cx="18.406" cy="5.594" r="1.44" />
        </SvgIcon>
    );
}
