import React, { ReactNode, CSSProperties } from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import OmitType from "customTypes/OmitType";
import clsx from "clsx";
import { transition } from "theme";

function getBorder(colour: string) {
    return `2px solid ${colour}`;
}

type IButtonStyle = {
    [key in StyledButtonVariant]: { highlighted: CSSProperties; default: CSSProperties };
};

const getButtonStyles = (theme: Theme, highlightedStyles?: CSSProperties): IButtonStyle => {
    return {
        primary: {
            default: {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
            },
            highlighted:
                typeof highlightedStyles !== "undefined"
                    ? highlightedStyles
                    : {
                          backgroundColor: theme.palette.primary.dark,
                          color: theme.palette.primary.contrastText,
                      },
        },
        secondary: {
            default: {
                border: getBorder(theme.palette.common.white),
                color: theme.palette.common.white,
            },
            highlighted:
                typeof highlightedStyles !== "undefined"
                    ? highlightedStyles
                    : {
                          backgroundColor: theme.palette.secondary.main,
                          color: theme.palette.secondary.contrastText,
                      },
        },
        transparent: {
            default: {
                border: getBorder(theme.palette.common.white),
                color: theme.palette.common.white,
            },
            highlighted:
                typeof highlightedStyles !== "undefined"
                    ? highlightedStyles
                    : {
                          backgroundColor: theme.palette.common.white,
                          color: theme.palette.common.black,
                      },
        },
    };
};

const useStyles = (highlighted: boolean, uppercase: boolean, highlightedStyles?: CSSProperties) =>
    makeStyles((theme: Theme) => {
        const buttonStyles = getButtonStyles(theme, highlightedStyles);

        const primaryTheme = highlighted
            ? buttonStyles.primary.highlighted
            : buttonStyles.primary.default;

        const secondaryTheme = highlighted
            ? buttonStyles.secondary.highlighted
            : buttonStyles.secondary.default;

        const transparentTheme = highlighted
            ? buttonStyles.transparent.highlighted
            : buttonStyles.transparent.default;

        return createStyles({
            root: {
                boxShadow: "none",
                textTransform: uppercase ? "uppercase" : "none",
                transition: `all ${transition.duration} ${transition.timingFunction}`,
                borderRadius: 5,
                border: getBorder("transparent"),
                whiteSpace: "nowrap",
                lineHeight: 1,
                padding: `${theme.spacing(0.8)}px ${theme.spacing(5)}px`,
                fontSize: "1rem",
                fontWeight: 700,
            },
            sizeSmall: {
                padding: theme.spacing(0.8),
                fontSize: "1rem",
            },
            sizeLarge: {
                padding: theme.spacing(1),
                fontSize: "1.8rem",
            },
            primary: {
                ...primaryTheme,
                "&:hover": buttonStyles.primary.highlighted,
            },
            secondary: {
                ...secondaryTheme,
                "&:hover": buttonStyles.secondary.highlighted,
            },
            transparent: {
                ...transparentTheme,
                "&:hover": buttonStyles.transparent.highlighted,
            },
        });
    });

type StyledButtonVariant = "primary" | "secondary" | "transparent";

export interface IStyledButtonProps extends OmitType<ButtonProps, "variant"> {
    children?: ReactNode;
    variant?: StyledButtonVariant;
    highlighted?: boolean;
    highlightedStyles?: CSSProperties;
    uppercase?: boolean;
}

export default function StyledButton(props: IStyledButtonProps) {
    const {
        children,
        className = "",
        variant = "primary",
        highlighted = false,
        highlightedStyles,
        uppercase = false,
        ...rest
    } = props;
    const classes = useStyles(highlighted, uppercase, highlightedStyles)();

    return (
        <Button
            className={clsx(classes.root, className, classes[variant])}
            classes={{ sizeSmall: classes.sizeSmall, sizeLarge: classes.sizeLarge }}
            {...rest}
        >
            {children}
        </Button>
    );
}
