import React from "react";
import LinkWrapper, { ILinkWrapperProps } from "./LinkWrapper";

interface IProps extends ILinkWrapperProps {}

export default function SameTabLink(props: IProps) {
    const { children, href, ...rest } = props;
    return (
        <LinkWrapper href={href} {...rest}>
            {children}
        </LinkWrapper>
    );
}
