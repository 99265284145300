import NonIntegerError from "errors/NonIntegerError";
import NonPositiveNumberError from "errors/NonPositiveNumberError";

export default function modSplitArray<T>(array: T[], numberOfOutputArrays: number): T[][] {
    if (numberOfOutputArrays <= 0)
        throw new NonPositiveNumberError("numberOfOutputArrays", numberOfOutputArrays);

    if (!Number.isInteger(numberOfOutputArrays))
        throw new NonIntegerError("numberOfOutputArrays", numberOfOutputArrays);

    if (array.length === 0) return [];

    if (numberOfOutputArrays >= array.length) return array.map((item) => [item]);

    let outputArray: T[][] = [];
    for (let index = 0; index < numberOfOutputArrays; index++) {
        outputArray.push([]);
    }

    array.forEach((element, index) => {
        const outputArrayIndex = index % numberOfOutputArrays;

        outputArray[outputArrayIndex].push(element);
    });

    return outputArray;
}
