import useResponsivePaddingSize from "hooks/useResponsivePaddingSize";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = (paddingSize: number) =>
    makeStyles((theme: Theme) => {
        return createStyles({
            responsivePadding: {
                padding: theme.spacing(paddingSize),
            },
        });
    });

export default function useResponsivePaddingClass() {
    const paddingSize = useResponsivePaddingSize();
    const classes = useStyles(paddingSize)();

    return classes.responsivePadding;
}
