import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { IPost } from "services/getCuratorContent";
import PostAvatar from "./PostAvatar";
import Spacer from "components/Spacer";
import PostContent from "./PostContent";
import PostCard from "./PostCard";
import { hoverOpacity } from "theme";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            flex: "1",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            "&:hover": {
                opacity: hoverOpacity,
            },
        },
    });
});

interface IProps {
    post: IPost;
}

export default function Post(props: IProps) {
    const classes = useStyles();
    const { post } = props;

    const { content, user } = post;

    return (
        <PostCard href={post.url} className={classes.root}>
            <PostContent content={content} />
            <Spacer size={3} />
            <PostAvatar user={user} />
        </PostCard>
    );
}
