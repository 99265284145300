const publicAssetsDirectory = `${process.env.PUBLIC_URL}/assets`;
const flagsLocation = `${publicAssetsDirectory}/flags`;

const assetLocations = {
    flags: {
        at: `${flagsLocation}/at.png`,
        au: `${flagsLocation}/au.png`,
        be: `${flagsLocation}/be.png`,
        ca: `${flagsLocation}/ca.png`,
        ch: `${flagsLocation}/ch.png`,
        de: `${flagsLocation}/de.png`,
        es: `${flagsLocation}/es.png`,
        fi: `${flagsLocation}/fi.png`,
        fr: `${flagsLocation}/fr.png`,
        it: `${flagsLocation}/it.png`,
        nl: `${flagsLocation}/nl.png`,
        no: `${flagsLocation}/no.png`,
        nz: `${flagsLocation}/nz.png`,
        pl: `${flagsLocation}/pl.png`,
        se: `${flagsLocation}/se.png`,
        uk: `${flagsLocation}/uk.png`,
        us: `${flagsLocation}/us.png`,
        world: `${flagsLocation}/world.png`,
    },
};

export default assetLocations;
