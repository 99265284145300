import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import GameDisplay from "./gameDisplay/GameDisplay";
import SocialWall, { socialWallPadding } from "./socialWall/SocialWall";
import GameDisplayBackground from "components/backgrounds/GameDisplayBackground";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: { flex: "1 1 auto", display: "flex", flexDirection: "column" },
    });
});

export default function HomePage() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <GameDisplayBackground
                style={{
                    ...socialWallPadding,
                }}
            >
                <GameDisplay />
            </GameDisplayBackground>
            <SocialWall />
        </div>
    );
}
