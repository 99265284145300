import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { IPost } from "services/getCuratorContent";
import Post from "./post/Post";
import PostSkeleton from "./post/PostSkeleton";
import Masonry, { IMasonryBreakPoint } from "components/masonry/Masonry";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            display: "flex",
            flexWrap: "wrap",
        },
        item: {
            margin: theme.spacing(1),
            minHeight: 250,
            display: "flex",
        },
    });
});

interface IProps {
    posts?: IPost[];
}

export default function SocialWallPosts(props: IProps) {
    const classes = useStyles();
    const { posts } = props;

    const masonryBreakPoints: IMasonryBreakPoint[] = [
        { breakpoint: "xs", numberOfColums: 1 },
        { breakpoint: "sm", numberOfColums: 2 },
        { breakpoint: "md", numberOfColums: 3 },
        { breakpoint: "lg", numberOfColums: 4 },
        { breakpoint: "xl", numberOfColums: 4 },
    ];

    return (
        <div className={classes.root}>
            {typeof posts === "undefined" ? (
                <Masonry
                    items={Array.from(new Array(10))}
                    renderItem={(_: any) => (
                        <div className={classes.item}>
                            <PostSkeleton />
                        </div>
                    )}
                    breakpoints={masonryBreakPoints}
                />
            ) : (
                <Masonry
                    items={posts}
                    renderItem={(post: IPost) => (
                        <div className={classes.item}>
                            <Post post={post} />
                        </div>
                    )}
                    breakpoints={masonryBreakPoints}
                />
            )}
        </div>
    );
}
