import React from "react";
import { makeStyles, createStyles, Theme, useTheme } from "@material-ui/core/styles";
import PostCard from "./PostCard";
import Skeleton from "@material-ui/lab/Skeleton";
import Spacer from "components/Spacer";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            flex: "1",
        },
    });
});

export default function PostSkeleton() {
    const classes = useStyles();
    const theme = useTheme();

    const animation = "wave";

    return (
        <PostCard className={classes.root}>
            <Skeleton animation={animation} variant="rect" height={250} />
            <Spacer size={3} />
            {Array.from(new Array(4)).map((_, index) => (
                <Skeleton animation={animation} variant="text" key={index} />
            ))}
            <Spacer size={3} />
            <div style={{ display: "flex" }}>
                <Skeleton
                    animation={animation}
                    variant="circle"
                    width={theme.spacing(6)}
                    height={theme.spacing(6)}
                />
                <div>
                    <Spacer size={2} />
                </div>
                <div style={{ flex: "1" }}>
                    <Skeleton animation={animation} variant="text" width={40} />
                    <Skeleton animation={animation} variant="text" width={80} />
                </div>
            </div>
        </PostCard>
    );
}
