import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import { ITileImages, ITileImage } from "services/getHomePageData";

export default function useTileImage(images: ITileImages): ITileImage {
    const theme = useTheme();

    const mobile = useMediaQuery(theme.breakpoints.down("xs"));
    const tablet = useMediaQuery(theme.breakpoints.down("md"));

    if (mobile) return images.mobile;
    if (tablet) return images.tablet;

    return images.desktop;
}
