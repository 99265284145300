import React, { ReactNode } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { SelectProps } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => {
    const primaryColour = theme.palette.common.white;

    return createStyles({
        root: {
            minWidth: 120,
            color: "white",
            "& .MuiSelect-root": {
                padding: theme.spacing(1),
            },
            "& .MuiInputBase-root": {
                color: "inherit",
            },
            "& .MuiFormLabel-root": {
                color: "inherit",
            },
            "& .MuiOutlinedInput-root": {
                borderRadius: 0,
            },
            "& .MuiSelect-selectMenu": {
                display: "flex",
                alignItems: "center",
            },
            "& .MuiListItemIcon-root": {
                minWidth: "unset",
                marginRight: theme.spacing(1),
            },
        },
        primary: {
            "& .MuiInputBase-root": {
                color: primaryColour,
            },
            "& .MuiFormLabel-root": {
                color: primaryColour,
            },
            "& .MuiSelect-icon": {
                color: primaryColour,
            },
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: primaryColour,
            },
            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.grey[500],
            },
            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.grey[800],
            },
        },
    });
});

type IVariant = "primary";

interface IProps {
    label?: string;
    variant?: IVariant;
    children?: ReactNode;
    selectProps?: SelectProps;
}

export default function StyledSelect(props: IProps) {
    const { children, label, variant = "primary", selectProps } = props;
    const classes = useStyles();

    return (
        <FormControl variant="outlined" className={classes.root}>
            <InputLabel>{label}</InputLabel>
            <Select className={clsx(classes[variant])} {...selectProps}>
                {children}
            </Select>
        </FormControl>
    );
}
