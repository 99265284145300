import axios from "axios";
import ICodemastersLogo from "models/ICodemastersLogo";
import IRating from "models/IRating";

interface ISettingsResponse {
    codemastersLogo: ICodemastersLogo;
    copyrightText: string;
    supportedCountries: string[];
    supportedRatings: IRating[];
}

export default async function getWebsiteSettings() {
    const response = await axios.get("/api/website/settings");

    const { data } = response;

    const settings: ISettingsResponse = {
        codemastersLogo: convertCodemasterLogoResponse(data.codemastersLogo[0]),
        copyrightText: data.copyrightText[0].html,
        supportedCountries: data.countryListCountries.map((c: any) => c.codename),
        supportedRatings: convertRatingsResponse(data.ratings),
    };

    return settings;
}

function convertRatingsResponse(ratings: any[]): IRating[] {
    return ratings.map((r: any) => {
        const logo = r.ratingLogo[0];

        const rating: IRating = {
            countries: r.countryListCountries.map((country: any) => {
                return { name: country.name, code: country.codename };
            }),
            imgSrc: logo.image[0].url,
            description: logo.image[0].description,
            href: logo.link,
            name: logo.name,
        };

        return rating;
    });
}

function convertCodemasterLogoResponse(codemastersLogo: any): ICodemastersLogo {
    const image = codemastersLogo.image[0];

    return {
        src: image.url,
        description: image.description,
        href: codemastersLogo.link,
        id: codemastersLogo.system.codename,
    };
}
