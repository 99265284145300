import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import PageSection from "components/layout/PageSection";
import SocialWallBackground from "components/backgrounds/SocialWallBackground";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import SocialMediaIcons from "components/SocialMediaIcons";
import SocialWallPosts from "./SocialWallPosts";
import getCuratorContent, { ICuratorResponse } from "services/getCuratorContent";
import Spacer from "components/Spacer";
import Scrollbar from "react-scrollbars-custom";
import { cssImportant } from "theme";
import useResponsivePaddingSize from "hooks/useResponsivePaddingSize";

const topMarginSize = "1.1vw";

export const socialWallPadding: CSSProperties = {
    paddingBottom: topMarginSize,
};

const useStyles = (paddingSize: number) =>
    makeStyles((theme: Theme) => {
        return createStyles({
            root: {
                height: "100%",
                borderBottom: `5px solid ${theme.palette.common.black}`,
                marginTop: `-${topMarginSize}`,
                "& .ScrollbarsCustom-Thumb": {
                    backgroundColor: theme.palette.secondary.main + cssImportant,
                },
                "& .ScrollbarsCustom-Track": {
                    backgroundColor: "#080808" + cssImportant,
                },
            },
            inner: {
                padding: `0px ${theme.spacing(paddingSize)}px`,
            },
            title: {
                textAlign: "center",
                textTransform: "uppercase",
                color: theme.palette.secondary.main,
                fontWeight: 800,
            },
            subTitle: {
                fontWeight: 800,
                textAlign: "center",
                lineHeight: "1",
            },
        });
    });

export default function SocialWall() {
    const paddingSize = useResponsivePaddingSize();
    const classes = useStyles(paddingSize)();

    const [data, setData] = useState<ICuratorResponse>();

    useEffect(() => {
        getCuratorContent().then((response) => setData(response));
    }, []);

    return (
        <SocialWallBackground className={classes.root}>
            <PageSection noPadding>
                <div className={classes.inner}>
                    <Spacer size={2} />
                    <Typography variant="h4" className={classes.title}>
                        Dirt Social Wall
                    </Typography>
                    <Typography variant="h6" className={classes.subTitle}>
                        Join in the conversation
                    </Typography>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <SocialMediaIcons />
                    </div>
                    <Spacer />
                    <Scrollbar style={{ width: "100%", height: 490 }}>
                        <SocialWallPosts posts={data?.posts} />
                    </Scrollbar>
                </div>
            </PageSection>
        </SocialWallBackground>
    );
}
