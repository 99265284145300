import React, { HTMLAttributes } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import NewTabLink from "components/links/NewTabLink";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => {
    const borderRadius = theme.spacing(2);

    return createStyles({
        root: {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.common.black,
            padding: theme.spacing(2),
            borderRadius: borderRadius,
            position: "relative",
        },
        link: {
            position: "absolute",
            height: "100%",
            width: "100%",
            top: 0,
            left: 0,
            borderRadius: borderRadius,
        },
    });
});

interface IProps extends HTMLAttributes<HTMLDivElement> {
    href?: string;
}

export default function PostCard(props: IProps) {
    const classes = useStyles();
    const { href, className, children, ...rest } = props;

    return (
        <div className={clsx(className, classes.root)} {...rest}>
            {typeof href === "undefined" ? null : (
                <NewTabLink href={href} underline="none" className={classes.link} />
            )}
            {children}
        </div>
    );
}
