import React, { ReactNode } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { layoutConsts } from "theme";
import clsx from "clsx";
import useResponsivePaddingClass from "hooks/useResponsivePaddingClass";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        contentWrapper: {
            flex: 1,
            display: "flex",
            justifyContent: "center",
        },
        maxWidthContainer: {
            flex: 1,
            display: "flex",
            flexDirection: "column",
            maxWidth: layoutConsts.siteContentMaxWidth,
        },
    });
});

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
    children?: ReactNode;
    noPadding?: boolean;
}

export default function PageSection(props: IProps) {
    const { children, className, noPadding = false, ...rest } = props;
    const classes = useStyles();
    const responsivePaddingClassName = useResponsivePaddingClass();

    return (
        <div className={clsx(className, classes.contentWrapper)} {...rest}>
            <div
                className={clsx(classes.maxWidthContainer, {
                    [responsivePaddingClassName]: !noPadding,
                })}
            >
                {children}
            </div>
        </div>
    );
}
