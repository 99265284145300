import React, { useState, ReactNode, useEffect } from "react";
import ConfigContext, { IAppConfig } from "contexts/ConfigContext";
import { useHistory } from "react-router-dom";
import getWebsiteSettings from "services/getWebsiteSettings";
import { defaultCountry } from "consts";

interface IProps {
    children?: ReactNode;
    country?: string;
}

export default function Application(props: IProps) {
    const { children, country } = props;
    const [config, setConfig] = useState<IAppConfig | undefined>(undefined);
    let history = useHistory();

    useEffect(() => {
        const resolvedCountry = resolveCountry(country);

        if (country !== resolvedCountry) {
            history.push(resolvedCountry);
        }

        getWebsiteSettings().then((settings) => {
            const {
                codemastersLogo,
                copyrightText,
                supportedCountries,
                supportedRatings,
            } = settings;

            let _country = resolvedCountry;
            const isCountry = supportedCountries.find(
                (c) => c.toLowerCase() === _country.toLowerCase()
            );
            if (typeof isCountry === "undefined") {
                history.push(defaultCountry);
                _country = defaultCountry;
            }

            const appConfig: IAppConfig = {
                codemastersLogo: codemastersLogo,
                country: _country,
                copyrightText: copyrightText,
                supportedCountries: supportedCountries,
                supportedRatings: supportedRatings,
            };

            setConfig(appConfig);
        });
    }, [history, country]);

    return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
}

function resolveCountry(country?: string): string {
    if (typeof country === "undefined" || country.length !== 2) return getCountryFromLanguage();

    return country.toLowerCase();
}

function getCountryFromLanguage() {
    const language = navigator.language;

    const parsedLanguage = language.substring(language.indexOf("-") + 1).toLowerCase();

    if (parsedLanguage === "en" || parsedLanguage === "gb") return "uk";

    return parsedLanguage.toLowerCase();
}
