import React, { ReactNode } from "react";
import StyledButton, { IStyledButtonProps } from "./StyledButton";
import SameTabLink from "components/links/SameTabLink";

interface IProps extends IStyledButtonProps {
    children?: ReactNode;
    href: string;
}

export default function SameTabLinkButton(props: IProps) {
    const { children, href, ...rest } = props;

    return (
        <StyledButton
            //@ts-ignore
            component={SameTabLink}
            href={href}
            underline="none"
            {...rest}
        >
            {children}
        </StyledButton>
    );
}
