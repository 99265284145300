import React from "react";
import { useParams } from "react-router-dom";
import Application from "containers/Application";
import PageLayout from "components/layout/PageLayout";
import HomePage from "pages/homePage/HomePage";

function App() {
    let { country } = useParams();

    return (
        <Application country={country}>
            <PageLayout>
                <HomePage />
            </PageLayout>
        </Application>
    );
}

export default App;
