import React from "react";
import { ITile } from "services/getHomePageData";
import { makeStyles, createStyles, Theme, fade } from "@material-ui/core/styles";
import TileDescription from "./TileDescription";
import clsx from "clsx";
import useTileImage from "hooks/useTileImage";
import BorderedTile from "components/BorderedTile";
import { transition, hoverFadeAmount } from "theme";
import SameTabLink from "components/links/SameTabLink";

const useStyles = (backgroundColour?: string) =>
    makeStyles((theme: Theme) => {
        return createStyles({
            root: {
                height: "100%",
            },
            inner: {
                display: "flex",
                flexDirection: "column",
                height: "100%",
                backgroundColor: backgroundColour,
                position: "relative",
                "&:hover $hoverOverlay": {
                    opacity: 1,
                },
            },
            hoverOverlay: {
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                opacity: 0,
                transition: `all ${transition.duration} ${transition.timingFunction}`,
                backgroundColor: fade(theme.palette.common.black, hoverFadeAmount),
            },
            backgroundImage: {
                height: "100%",
                width: "100%",
            },
            backgroundImageExtendible: {
                height: "unset",
            },
            descriptionContainer: {
                pointerEvents: "none",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                padding: theme.spacing(2),
                boxSizing: "border-box",
                [theme.breakpoints.down("md")]: {
                    padding: theme.spacing(1),
                },
            },
            descriptionContainerPlaceholder: {
                visibility: "hidden",
            },
            descriptionContainerPlaceholderFeature: {
                [theme.breakpoints.down("md")]: {
                    marginTop: "-13.4%",
                },
                [theme.breakpoints.down("xs")]: {
                    marginTop: "-37.1%",
                },
            },
            descriptionContainerPlaceholderDefault: {
                marginTop: "-49.5%",
                [theme.breakpoints.down("md")]: {
                    marginTop: "-12.7%",
                },
                [theme.breakpoints.down("xs")]: {
                    marginTop: "-27.1%",
                },
            },
            descriptionContainerAbsolute: {
                position: "absolute",
                bottom: 0,
            },
            descriptionContainerAbsoluteExtendible: {
                bottom: "2%",
                [theme.breakpoints.down("xs")]: {
                    bottom: "0",
                },
            },
            descriptionInner: {
                maxWidth: 750,
            },
        });
    });

interface IProps {
    tile: ITile;
    feature?: boolean;
}

export default function Tile(props: IProps) {
    const { tile, feature = false } = props;

    const image = useTileImage(tile.images);
    const { backgroundColour } = image;

    const classes = useStyles(backgroundColour)();
    const extendible = typeof backgroundColour !== "undefined" && backgroundColour.length > 0;

    return (
        <BorderedTile className={classes.root} classes={{ inner: classes.inner }}>
            <SameTabLink
                href={tile.button.href}
                className={classes.hoverOverlay}
                underline="none"
            />

            <img
                src={image.src}
                alt={image.description}
                className={clsx(classes.backgroundImage, {
                    [classes.backgroundImageExtendible]: extendible,
                })}
            />
            <div
                className={clsx(
                    classes.descriptionContainer,
                    classes.descriptionContainerAbsolute,
                    {
                        [classes.descriptionContainerAbsoluteExtendible]: extendible,
                    }
                )}
            >
                <TileDescription className={classes.descriptionInner} tile={tile} />
            </div>
            {extendible ? (
                <div
                    className={clsx(
                        classes.descriptionContainer,
                        classes.descriptionContainerPlaceholder,
                        feature
                            ? classes.descriptionContainerPlaceholderFeature
                            : classes.descriptionContainerPlaceholderDefault
                    )}
                >
                    <TileDescription className={classes.descriptionInner} tile={tile} />
                </div>
            ) : null}
        </BorderedTile>
    );
}
