import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function TwitterIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M22.675,0H1.325C0.593,0,0,0.593,0,1.325v21.351C0,23.407,0.593,24,1.325,24h11.494v-9.294 H9.691v-3.622h3.128V8.413c0-3.1,1.893-4.788,4.659-4.788c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001 c-1.504,0-1.795,0.715-1.795,1.763v2.313h3.587l-0.467,3.622h-3.12V24h6.116C23.407,24,24,23.407,24,22.675V1.325 C24,0.593,23.407,0,22.675,0z" />
        </SvgIcon>
    );
}
