import React, { HTMLAttributes } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import borderBackground from "assets/images/backgrounds/bg_paneledge.jpg"

const useStyles = makeStyles((theme: Theme) => {
    const borderSize = 4;

    const innerBorderRadius = 12;
    const outerBorderRadius = 15;

    const borderOffset = 3;

    return createStyles({
        root: {
            backgroundImage: `url(${borderBackground})`,
            backgroundSize: "contain",
            backgroundPositionY: "100%",
            backgroundColor: theme.palette.common.white,
            paddingLeft: borderSize,
            paddingBottom: borderSize,
            borderRadius: outerBorderRadius,
            marginTop: borderOffset,
            marginRight: borderOffset,
            boxSizing: "border-box",
        },
        inner: {
            display: "flex",
            width: `calc(100% + ${borderOffset}px)`,
            borderRadius: innerBorderRadius,
            marginTop: -borderOffset,
            overflow: "hidden",
            boxSizing: "border-box",
        },
    });
});

type IClasses = Partial<ReturnType<typeof useStyles>>;

interface IProps extends HTMLAttributes<HTMLDivElement> {
    classes?: IClasses;
}

export default function BorderedTile(props: IProps) {
    const classes = useStyles();
    const { className, children, classes: overideClasses, ...rest } = props;

    return (
        <div className={clsx(className, classes.root, overideClasses?.root)} {...rest}>
            <div className={clsx(classes.inner, overideClasses?.inner)}>{children}</div>
        </div>
    );
}
