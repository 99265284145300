import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

type BreakpointPadding = {
    [key in Breakpoint]: number;
};

export default function useResponsivePaddingSize() {
    const theme = useTheme();

    const defaultValue = 3;
    const paddingValues: BreakpointPadding = {
        xl: defaultValue,
        lg: defaultValue,
        md: 2,
        sm: 2,
        xs: 1,
    };

    const xs = useMediaQuery(theme.breakpoints.only("xs"));
    const sm = useMediaQuery(theme.breakpoints.only("sm"));
    const md = useMediaQuery(theme.breakpoints.only("md"));
    const lg = useMediaQuery(theme.breakpoints.only("lg"));
    const xl = useMediaQuery(theme.breakpoints.only("xl"));

    if (xs) return paddingValues.xs;
    if (sm) return paddingValues.sm;
    if (md) return paddingValues.md;
    if (lg) return paddingValues.lg;
    if (xl) return paddingValues.xl;

    return defaultValue;
}
