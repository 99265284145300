import React from "react";
import { useTheme } from "@material-ui/core/styles";
import useResponsivePaddingSize from "hooks/useResponsivePaddingSize";

interface IProps {
    multiplier?: number;
}

export default function ResponsiveSpacer(props: IProps) {
    const theme = useTheme();
    const { multiplier = 1 } = props;

    const size = useResponsivePaddingSize();
    const dimension = theme.spacing(size * multiplier);

    return <div style={{ height: dimension, width: dimension, flex: "1 0 auto" }} />;
}
