import axios from "axios";

export interface IUser {
    avatarImgSrc?: string;
    url?: string;
    fullName: string;
    screenName: string;
}

interface IPostImage {
    src: string;
}

interface IPostVideo {
    src: string;
}

export interface IPostContent {
    text: string;
    image?: IPostImage;
    video?: IPostVideo;
    thumbnail: string;
}

export interface IPost {
    url: string;
    content: IPostContent;
    user: IUser;
}

export interface ICuratorResponse {
    posts: IPost[];
}

export default async function getCuratorContent() {
    const response = await axios.get("/api/socialfeed");

    const { data } = response;

    const settings: ICuratorResponse = {
        posts: convertPostsResponse(data),
    };

    return settings;
}

function convertPostsResponse(data: any): IPost[] {
    return data.posts.map((post: any) => {
        return {
            url: post.url,
            content: getPostContentResponse(post),
            user: getPostUserResponse(post),
        };
    });
}

function getPostContentResponse(post: any): IPostContent {
    return {
        text: post.text,
        image: post.image.length > 0 ? getPostImageResponse(post) : undefined,
        video: post.video.length > 0 ? getPostVideoResponse(post) : undefined,
        thumbnail: post.thumbnail,
    };
}

function getPostImageResponse(post: any): IPostImage {
    return {
        src: post.image,
    };
}

function getPostVideoResponse(post: any): IPostImage {
    return {
        src: post.video,
    };
}

function getPostUserResponse(post: any): IUser {
    return {
        avatarImgSrc: post.user_image,
        url: post.userUrl,
        fullName: post.user_full_name,
        screenName: post.user_screen_name,
    };
}
