import React, { HtmlHTMLAttributes } from "react";

export type FadeAnimation =
    | "fade"
    | "fade-up"
    | "fade-down"
    | "fade-left"
    | "fade-right"
    | "fade-up-right"
    | "fade-up-left"
    | "fade-down-right"
    | "fade-down-left";

export type FlipAnimation = "flip-up" | "flip-down" | "flip-left" | "flip-right";

export type SlideAnimation = "slide-up" | "slide-down" | "slide-left" | "slide-right";

export type ZoomAnimation =
    | "zoom-in"
    | "zoom-in-up"
    | "zoom-in-down"
    | "zoom-in-left"
    | "zoom-in-right"
    | "zoom-out"
    | "zoom-out-up"
    | "zoom-out-down"
    | "zoom-out-left"
    | "zoom-out-right";

export type AosAnimation = FadeAnimation | FlipAnimation | SlideAnimation | ZoomAnimation;

export type AnchorPlacement =
    | "top-bottom"
    | "top-center"
    | "top-top"
    | "center-bottom"
    | "center-center"
    | "center-top"
    | "bottom-bottom"
    | "bottom-center"
    | "bottom-top";

export type EasingFunction =
    | "linear"
    | "ease"
    | "ease-in"
    | "ease-out"
    | "ease-in-out"
    | "ease-in-back"
    | "ease-out-back"
    | "ease-in-out-back"
    | "ease-in-sine"
    | "ease-out-sine"
    | "ease-in-out-sine"
    | "ease-in-quad"
    | "ease-out-quad"
    | "ease-in-out-quad"
    | "ease-in-cubic"
    | "ease-out-cubic"
    | "ease-in-out-cubic"
    | "ease-in-quart"
    | "ease-out-quart"
    | "ease-in-out-quart";

export interface IAosWrapperProps extends HtmlHTMLAttributes<HTMLDivElement> {
    animation: AosAnimation;
    offset?: number;
    delay?: number;
    duration?: number;
    easingFunction?: EasingFunction;
    mirror?: boolean;
    once?: boolean;
    anchorPlacement?: AnchorPlacement;
}

export default function AosWrapper(props: IAosWrapperProps) {
    const {
        animation,
        offset = 120,
        delay = 0,
        duration = 400,
        easingFunction = "ease",
        mirror = false,
        once = false,
        anchorPlacement = "top-bottom",
        children,
        ...rest
    } = props;

    return (
        <div
            data-aos={animation}
            data-aos-offset={offset}
            data-aos-delay={delay}
            data-aos-duration={duration}
            data-aos-easing={easingFunction}
            data-aos-mirror={mirror}
            data-aos-once={once}
            data-aos-anchor-placement={anchorPlacement}
            {...rest}
        >
            {children}
        </div>
    );
}
