import axios from "axios";

interface IMetaData {
    keywords: string;
    title: string;
    description: string;
}

export interface ITileImage {
    src: string;
    description: string;
    backgroundColour?: string;
}

interface ITileButton {
    text: string;
    href: string;
    hoverColour?: string;
}

export interface ITileImages {
    desktop: ITileImage;
    tablet: ITileImage;
    mobile: ITileImage;
}

export interface ITile {
    name: string;
    images: ITileImages;
    description: string;
    button: ITileButton;
}

export interface IHomePageData {
    metaData: IMetaData;
    tiles: ITile[];
}

export default async function getHomePageData(country: string) {
    const response = await axios.get(`/api/website/home_page/${country}`);

    const { data } = response;

    const homePageData: IHomePageData = {
        metaData: convertMetaDataResponse(data),
        tiles: convertTilesResponse(data),
    };

    return homePageData;
}

function convertMetaDataResponse(data: any): IMetaData {
    return {
        keywords: data.metadataKeywords,
        title: data.metadataTitle,
        description: data.metadataDescription,
    };
}

function convertTilesResponse(data: any): ITile[] {
    return data.tiles.map((tile: any) => convertTileResponse(tile));
}

function convertTileResponse(tileResponse: any): ITile {
    return {
        name: tileResponse.system.name,
        images: convertTileImagesResponse(tileResponse),
        description: tileResponse.description,
        button: convertTileButtonResponse(tileResponse.button[0][0]),
    };
}

function convertTileButtonResponse(tileButtonResponse: any): ITileButton {
    const { text, link, hoverColour } = tileButtonResponse;
    return {
        text: text,
        href: link,
        hoverColour: hoverColour.length === 0 ? undefined : hoverColour,
    };
}

function convertTileImagesResponse(tileResponse: any): ITileImages {
    const images: ITileImages = {
        desktop: convertTileImageResponse(tileResponse.desktopImage[0][0]),
        tablet: convertTileImageResponse(tileResponse.tabletImage[0][0]),
        mobile: convertTileImageResponse(tileResponse.mobileImage[0][0]),
    };

    return images;
}

function convertTileImageResponse(imageResponse: any): ITileImage {
    const image = imageResponse.image[0];

    return {
        src: image.url,
        description: image.description,
        backgroundColour: imageResponse.backgroundColour,
    };
}
