import { createContext } from "react";
import ICodemastersLogo from "models/ICodemastersLogo";
import IRating from "models/IRating";

export interface IAppConfig {
    codemastersLogo: ICodemastersLogo;
    country: string;
    copyrightText: string;
    supportedCountries: string[];
    supportedRatings: IRating[];
}

const ConfigContext = createContext<IAppConfig | undefined>(undefined);

export default ConfigContext;
