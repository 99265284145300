import React, { ReactNode, HtmlHTMLAttributes } from "react";
import RandomFlip from "./RandomFlip";
import AosWrapper from "./AosWrapper";

interface IProps extends HtmlHTMLAttributes<HTMLDivElement> {
    order: number;
    children: ReactNode;
}

export default function DefaultAosAnimation(props: IProps) {
    const { order, children, className, ...rest } = props;
    const delay = 150 * order;
    const duration = 400;
    const once = true;

    return (
        <RandomFlip delay={delay} duration={duration} once={once} className={className} {...rest}>
            <AosWrapper
                animation="fade-up"
                delay={delay}
                duration={duration}
                once={once}
                className={className}
            >
                {children}
            </AosWrapper>
        </RandomFlip>
    );
}
