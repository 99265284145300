import React, { ReactNode } from "react";

import IconButton from "@material-ui/core/IconButton";
import TwitterIcon from "assets/icons/socialMedia/TwitterIcon";
import FacebookIcon from "assets/icons/socialMedia/FacebookIcon";
import InstagramIcon from "assets/icons/socialMedia/InstagramIcon";
import YouTubeIcon from "assets/icons/socialMedia/YouTubeIcon";

import NewTabLink from "./links/NewTabLink";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { transition } from "theme";
import links from "links";
import clsx from "clsx";

const socialMediaColours = {
    twitter: "#1da1f2",
    facebook: "#3d5a99",
    youtube: "#f00",
    instagram: "#ea0d78",
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
        },
        icon: {
            color: theme.palette.common.white,
            transition: `all ${transition.duration} ${transition.timingFunction}`,
        },
        twitter: {
            "&:hover": {
                color: socialMediaColours.twitter,
                backgroundColor: theme.palette.common.white,
            },
        },
        facebook: {
            "&:hover": {
                color: socialMediaColours.facebook,
                backgroundColor: theme.palette.common.white,
            },
        },
        youtube: {
            "&:hover": {
                color: socialMediaColours.youtube,
                backgroundColor: theme.palette.common.white,
            },
        },
        instagram: {
            "&:hover": {
                color: socialMediaColours.instagram,
                backgroundColor: theme.palette.common.white,
            },
        },
    })
);

interface IIconLink {
    icon: ReactNode;
    link: string;
    className?: string;
}

export default function SocialMediaIcons() {
    const classes = useStyles();

    const iconLinks: IIconLink[] = [
        {
            icon: <TwitterIcon />,
            link: links.socialMedia.twitter,
            className: classes.twitter,
        },
        {
            icon: <FacebookIcon />,
            link: links.socialMedia.facebook,
            className: classes.facebook,
        },
        {
            icon: <YouTubeIcon />,
            link: links.socialMedia.youtube,
            className: classes.youtube,
        },
        {
            icon: <InstagramIcon />,
            link: links.socialMedia.instagram,
            className: classes.instagram,
        },
    ];

    return (
        <div className={classes.root}>
            {iconLinks.map((iconLink: IIconLink, i) => {
                const { link, icon, className = "" } = iconLink;
                return (
                    <NewTabLink key={i} href={link}>
                        <IconButton color="inherit" className={clsx(classes.icon, className)}>
                            {icon}
                        </IconButton>
                    </NewTabLink>
                );
            })}
        </div>
    );
}
