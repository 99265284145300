import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function TwitterIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M7.588,21.641c9.057,0,14.01-7.503,14.01-14.01c0-0.213,0-0.425-0.014-0.636 c0.964-0.697,1.796-1.56,2.457-2.549c-0.899,0.398-1.852,0.659-2.828,0.775c1.028-0.615,1.797-1.583,2.165-2.724 C22.41,3.07,21.353,3.475,20.25,3.692c-1.865-1.983-4.984-2.079-6.967-0.214c-1.279,1.203-1.821,2.995-1.424,4.705 C7.899,7.984,4.211,6.114,1.71,3.038c-1.307,2.25-0.639,5.128,1.524,6.573C2.451,9.588,1.685,9.377,1,8.995c0,0.02,0,0.041,0,0.062 c0.001,2.344,1.653,4.363,3.95,4.827c-0.725,0.198-1.486,0.227-2.223,0.084c0.645,2.006,2.494,3.38,4.6,3.42 c-1.744,1.37-3.898,2.114-6.115,2.112C0.82,19.5,0.429,19.476,0.04,19.429c2.252,1.445,4.872,2.212,7.548,2.208" />
        </SvgIcon>
    );
}
