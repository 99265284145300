import React, { HTMLAttributes } from "react";
import BackgroundImage from "./BackgroundImage";
import backgroundImageSrc from "assets/images/backgrounds/body.jpg"

interface IProps extends HTMLAttributes<HTMLDivElement> {}

export default function GameDisplayBackground(props: IProps) {
    return (
        <BackgroundImage
            backgroundStyle={{
                imageUrl: backgroundImageSrc,
                backgroundSize: "cover",
                flex: 1,
            }}
            {...props}
        />
    );
}
