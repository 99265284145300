import React from "react";
import { useTheme } from "@material-ui/core/styles";

interface IProps {
    size?: number;
}

export default function Spacer(props: IProps) {
    const theme = useTheme();
    const { size = 1 } = props;

    const dimension = theme.spacing(size);

    return <div style={{ height: dimension, width: dimension, flex: "1 0 auto" }} />;
}
