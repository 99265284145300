import { modSplitArray } from "utils/arrayUtils";

export type IColumn<T> = T[];

type ICachedColumns<T> = {
    [numberOfColumns: number]: IColumn<T>[];
};

export default class MasonryController<T> {
    private readonly _list: T[];
    private _cachedColumns: ICachedColumns<T>;

    constructor(list: T[]) {
        this._list = list;
        this._cachedColumns = {};
    }

    public getColumns(numberOfColumns: number): IColumn<T>[] {
        if (this._cachedColumns.hasOwnProperty(numberOfColumns))
            return this._cachedColumns[numberOfColumns];

        this._cachedColumns[numberOfColumns] = this.calculateColumns(numberOfColumns);

        return this._cachedColumns[numberOfColumns];
    }

    private calculateColumns(numberOfColumns: number): IColumn<T>[] {
        return modSplitArray(this._list, numberOfColumns);
    }
}
