import React, { useState, useEffect } from "react";
import getHomePageData, { IHomePageData } from "services/getHomePageData";
import Tiles from "./tiles/Tiles";
import PageSection from "components/layout/PageSection";

//@ts-ignore
import Helmet from "react-helmet";

interface IProps {
    country: string;
}

export default function Content(props: IProps) {
    const { country } = props;
    const [data, setData] = useState<IHomePageData>();

    useEffect(() => {
        getHomePageData(country).then((response) => setData(response));
    }, [country]);

    return typeof data === "undefined" ? null : (
        <>
            <Helmet>
                <title>{data.metaData.title}</title>
                <meta name="keywords" content={data.metaData.keywords} />
                <meta name="title" content={data.metaData.title} />
                <meta name="description" content={data.metaData.description} />
            </Helmet>
            <PageSection>
                <Tiles tiles={data.tiles} />
            </PageSection>
        </>
    );
}
