import React, { HTMLAttributes } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

const useStyles = (styleProps: IBackgroundStyle) =>
    makeStyles(() => {
        const { imageUrl, ...rest } = styleProps;

        return createStyles({
            root: {
                backgroundImage: `url(${imageUrl})`,
                ...rest,
            },
        });
    });

interface IBackgroundStyle extends CSSProperties {
    imageUrl: string;
}

interface IProps extends HTMLAttributes<HTMLDivElement> {
    backgroundStyle: IBackgroundStyle;
}

export default function BackgroundImage(props: IProps) {
    const { children, className = "", backgroundStyle, ...rest } = props;
    const classes = useStyles(backgroundStyle)();

    return (
        <div className={`${className} ${classes.root}`} {...rest}>
            {children}
        </div>
    );
}
