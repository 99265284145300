import React, { HTMLAttributes } from "react";
import { makeStyles, createStyles, Theme, fade } from "@material-ui/core/styles";
import clsx from "clsx";
import BorderedTile from "components/BorderedTile";
import Typography from "@material-ui/core/Typography";
import backgroundImageSrc from "./images/bg_dishdirt.jpg";
import leftThumbnailImageSrc from "./images/thumbnails_left.png";
import rightThumbnailImageSrc from "./images/thumbnails_right.png";
import textboxBackgroundImageSrc from "./images/txt_bg_box.png";
import titleImageSrc from "./images/txt_dishdirt_title.png";
import SameTabLinkButton from "components/buttons/SameTabLinkButton";
import SameTabLink from "components/links/SameTabLink";
import { transition, hoverFadeAmount } from "theme";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {},
        inner: {
            backgroundImage: `url(${backgroundImageSrc})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            display: "flex",
            justifyContent: "space-between",
            [theme.breakpoints.down("sm")]: {
                justifyContent: "center",
            },
            position: "relative",
            "&:hover $hoverOverlay": {
                opacity: 1,
            },
        },
        hoverOverlay: {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 1,
            opacity: 0,
            transition: `all ${transition.duration} ${transition.timingFunction}`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: fade(theme.palette.common.black, hoverFadeAmount),
        },
        thumbnailsContainer: {
            flex: 1,
            position: "relative",
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        },
        thumbnailImage: {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            "& img": {
                width: "100%",
                maxWidth: 350,
            },
        },
        thumbnailImageLeft: {
            justifyContent: "flex-start",
        },
        thumbnailImageRight: {
            justifyContent: "flex-end",
        },
        textSection: {
            flex: 2,
            margin: theme.spacing(3),
            maxWidth: 675,
        },
        titleContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        titleImage: {
            width: "100%",
            maxWidth: 575,
        },
        textDescription: {
            backgroundImage: `url(${textboxBackgroundImageSrc})`,
            backgroundSize: "100% 100%",
            padding: theme.spacing(3),
            marginTop: -12,
        },
    });
});

interface IProps extends HTMLAttributes<HTMLDivElement> {}

export default function DishingTheDirtBanner(props: IProps) {
    const classes = useStyles();
    const { className, children, ...rest } = props;

    const siteUrl = "https://dishingthedirt.dirtgame.com/";

    return (
        <BorderedTile
            className={clsx(className, classes.root)}
            classes={{ inner: classes.inner }}
            {...rest}
        >
            <SameTabLink href={siteUrl} className={classes.hoverOverlay} underline="none">
                <SameTabLinkButton href={siteUrl} variant="transparent">
                    Visit Site
                </SameTabLinkButton>
            </SameTabLink>

            <div className={classes.thumbnailsContainer}>
                <div className={clsx(classes.thumbnailImage, classes.thumbnailImageLeft)}>
                    <img src={leftThumbnailImageSrc} />
                </div>
            </div>

            <div className={classes.textSection}>
                <div className={classes.titleContainer}>
                    <img className={classes.titleImage} src={titleImageSrc} />
                </div>
                <div className={classes.textDescription}>
                    <Typography style={{ fontWeight: "bold" }}>
                        Hear from the Dirt 5 and Dirt Rally 2.0 teams on how their different design
                        ethos makes for two very different games
                    </Typography>
                </div>
            </div>

            <div className={classes.thumbnailsContainer}>
                <div className={clsx(classes.thumbnailImage, classes.thumbnailImageRight)}>
                    <img src={rightThumbnailImageSrc} />
                </div>
            </div>
        </BorderedTile>
    );
}
