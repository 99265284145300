import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
// import Subscription from "./subscription/Subscription";
import LicensingSection from "./licensing/LicensingSection";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: { backgroundColor: "#101113" },
    });
});

export default function Footer() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {/* <Subscription /> */}
            <LicensingSection />
            <div
                id="consent_blackbar"
                style={{
                    position: "fixed",
                    bottom: 0,
                    zIndex: 100,
                    width: "100%",
                }}
                className="consent-blackbar"
            ></div>
        </div>
    );
}
