import React, { HTMLAttributes } from "react";
import BackgroundImage from "./BackgroundImage";
import backgroundImageSrc from "assets/images/backgrounds/banner.jpg"

interface IProps extends HTMLAttributes<HTMLDivElement> {}

export default function TopBannerBackground(props: IProps) {
    return (
        <BackgroundImage
            backgroundStyle={{
                imageUrl: backgroundImageSrc,
                backgroundSize: "cover",
            }}
            {...props}
        />
    );
}
