import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import App from "./App";
import { defaultCountry } from "consts";
import * as serviceWorker from "./serviceWorker";
import "typeface-montserrat";
//@ts-ignore
// import TagManager from "react-gtm-module";

import AOS from "aos";
import "aos/dist/aos.css";

import { MuiThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import "index.css";

// const tagManagerArgs = {
//     gtmId: "GTM-K7XPKPR",
// };

// TagManager.initialize(tagManagerArgs);

AOS.init();

// Internet Explorer 6-11
// @ts-ignore
const isIE = /*@cc_on!@*/ false || !!document.documentMode;

ReactDOM.render(
    <React.StrictMode>
        {isIE ? (
            <div style={{ color: "black" }}>
                Internet Explorer 11 is not supported, please use another browser.
            </div>
        ) : (
            <MuiThemeProvider theme={theme}>
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/:country">
                            <App />
                        </Route>
                        <Redirect to={`/${defaultCountry}`} />
                    </Switch>
                </BrowserRouter>
            </MuiThemeProvider>
        )}
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
