import React from "react";
import { makeStyles, createStyles, Theme, useTheme } from "@material-ui/core/styles";
import NewTabLink from "components/links/NewTabLink";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Spacer from "components/Spacer";
import links from "links";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            [theme.breakpoints.down("xs")]: { flexDirection: "column", alignItems: "center" },
        },
        consent: {
            "&:hover": {
                textDecoration: "underline",
            },
        },
    })
);

interface ILegalLink {
    link: string;
    label: string;
}

export default function LegalLinks() {
    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const legalLinks: ILegalLink[] = [
        {
            link: links.legal.termsAndConditions,
            label: "Terms & Conditions",
        },
        {
            link: links.legal.privacyPolicy,
            label: "Privacy Policy",
        },
        {
            link: links.legal.contactUs,
            label: "Contact Us",
        },
    ];

    return (
        <div className={classes.root}>
            <div style={{ display: "flex" }}>
                <Typography variant="body1">
                    <NewTabLink
                        style={{ color: theme.palette.common.white }}
                        href={legalLinks[0].link}
                    >
                        {legalLinks[0].label}
                    </NewTabLink>
                </Typography>
                {smallScreen ? (
                    <Spacer />
                ) : (
                    <span style={{ margin: `0px ${theme.spacing(2)}px` }}>|</span>
                )}
                <Typography variant="body1">
                    <NewTabLink
                        style={{ color: theme.palette.common.white }}
                        href={legalLinks[1].link}
                    >
                        {legalLinks[1].label}
                    </NewTabLink>
                </Typography>
                {smallScreen ? (
                    <Spacer />
                ) : (
                    <span style={{ margin: `0px ${theme.spacing(2)}px` }}>|</span>
                )}
                <Typography variant="body1">
                    <NewTabLink
                        style={{ color: theme.palette.common.white }}
                        href={legalLinks[2].link}
                    >
                        {legalLinks[2].label}
                    </NewTabLink>
                </Typography>
                {smallScreen ? (
                    <Spacer />
                ) : (
                    <span style={{ margin: `0px ${theme.spacing(2)}px` }}>|</span>
                )}
                <Typography className={classes.consent} variant="body1">
                    <div style={{ color: theme.palette.common.white }} id="teconsent"></div>
                </Typography>
            </div>

            {/* {legalLinks.map((legalLink: ILegalLink, i) => {
                const { link, label } = legalLink;

                const showBar = i !== legalLinks.length - 1;

                return (
                    <div key={i} style={{ display: "flex" }}>
                        <Typography variant="body1">
                            <NewTabLink style={{ color: theme.palette.common.white }} href={link}>
                                {label}
                            </NewTabLink>
                        </Typography>
                        {showBar ? (
                            smallScreen ? (
                                <Spacer />
                            ) : (
                                <span style={{ margin: `0px ${theme.spacing(2)}px` }}>|</span>
                            )
                        ) : null}
                    </div>
                );
            })} */}
        </div>
    );
}
