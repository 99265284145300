import React, { useContext } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import ConfigContext from "contexts/ConfigContext";
import LanguageSelector from "./languageSelector/LanguageSelector";
import Rating from "./rating/Rating";
import PageSection from "components/layout/PageSection";
import LegalLinks from "./legalLinks/LegalLinks";
import Typography from "@material-ui/core/Typography";
import NewTabLink from "components/links/NewTabLink";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
            },
        },
        codemastersLogo: {
            minWidth: 200,
            "& img": {
                width: "100%",
            },
            "&:hover": {
                "& img": {
                    opacity: 0.8,
                },
            },
        },
        copyrightText: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            padding: `0px ${theme.spacing(2)}px`,
        },
        rightContent: {
            display: "flex",
            alignItems: "center",
            [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
                paddingTop: theme.spacing(2),
            },
        },
    })
);

export default function LicensingSection() {
    const classes = useStyles();
    const config = useContext(ConfigContext);

    if (typeof config === "undefined") return null;

    const { codemastersLogo, copyrightText } = config;

    return (
        <PageSection>
            <div className={classes.root}>
                <NewTabLink href="https://www.codemasters.com/" className={classes.codemastersLogo}>
                    <img src={codemastersLogo.src} alt="CodemastersLogo" />
                </NewTabLink>
                <div className={classes.copyrightText}>
                    <Typography
                        variant="body2"
                        dangerouslySetInnerHTML={{ __html: copyrightText }}
                    />
                    <LegalLinks />
                </div>
                <div className={classes.rightContent}>
                    <LanguageSelector />
                    <Rating />
                </div>
            </div>
        </PageSection>
    );
}
