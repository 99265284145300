import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { ITile } from "services/getHomePageData";
import Tile from "./Tile";
import ResponsiveSpacer from "components/ResponsiveSpacer";
import DefaultAosAnimation from "components/aos/DefaultAosAnimation";
import DishingTheDirtBanner from "components/dishingTheDirtBanner/DishingTheDirtBanner";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        subTileContainer: {
            display: "flex",
            [theme.breakpoints.down("md")]: {
                flexDirection: "column",
            },
        },
        aosContainer: {
            height: "100%",
            [theme.breakpoints.down("md")]: {
                height: "unset",
            },
        },
    });
});

interface IProps {
    tiles: ITile[];
}

export default function Tiles(props: IProps) {
    const classes = useStyles();
    const { tiles } = props;

    let _tiles = [...tiles];

    const featureTile = _tiles.shift();

    return (
        <>
            {typeof featureTile === "undefined" ? null : (
                <DefaultAosAnimation order={1} className={classes.aosContainer}>
                    <Tile tile={featureTile} feature />
                </DefaultAosAnimation>
            )}
            <ResponsiveSpacer />

            <DefaultAosAnimation order={2} className={classes.aosContainer}>
                <DishingTheDirtBanner />
            </DefaultAosAnimation>

            <ResponsiveSpacer />

            <div className={classes.subTileContainer}>
                {_tiles.map((tile, i) => (
                    <React.Fragment key={i}>
                        <ResponsiveSpacer multiplier={i === 0 ? 0 : 1} />
                        <DefaultAosAnimation order={i + 3} className={classes.aosContainer}>
                            <Tile tile={tile} />
                        </DefaultAosAnimation>
                    </React.Fragment>
                ))}
            </div>
        </>
    );
}
