import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { IUser } from "services/getCuratorContent";
import Avatar from "@material-ui/core/Avatar";
import Spacer from "components/Spacer";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: { display: "flex" },
        avatar: {
            width: theme.spacing(6),
            height: theme.spacing(6),
        },
    });
});

interface IProps {
    user: IUser;
}

export default function PostAvatar(props: IProps) {
    const classes = useStyles();
    const { user } = props;

    return (
        <div className={classes.root}>
            <Avatar alt={user.screenName} src={user.avatarImgSrc} className={classes.avatar} />
            <div>
                <Spacer size={2} />
            </div>
            <div>
                <Typography variant="body2">{user.fullName}</Typography>
                <Typography variant="body2">@{user.screenName}</Typography>
            </div>
        </div>
    );
}
