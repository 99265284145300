import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function YouTubeIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M23.498,6.921c-0.276-1.039-1.089-1.858-2.122-2.136C19.505,4.28,12,4.28,12,4.28s-7.505,0-9.377,0.505 C1.591,5.063,0.778,5.882,0.502,6.921C0,8.805,0,12.735,0,12.735s0,3.93,0.502,5.814c0.276,1.039,1.089,1.824,2.122,2.102 C4.495,21.155,12,21.155,12,21.155s7.505,0,9.377-0.505c1.033-0.278,1.846-1.062,2.122-2.102C24,16.665,24,12.735,24,12.735 S24,8.805,23.498,6.921z M9.545,16.303V9.167l6.273,3.569L9.545,16.303L9.545,16.303z" />
        </SvgIcon>
    );
}
