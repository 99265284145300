import React from "react";
import { ITile } from "services/getHomePageData";
import { makeStyles, createStyles, Theme, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ResponsiveSpacer from "components/ResponsiveSpacer";
import SameTabLinkButton from "components/buttons/SameTabLinkButton";
import clsx from "clsx";
import backgroundImageSrc from "assets/images/backgrounds/bg_info.png";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            backgroundImage: `url(${backgroundImageSrc})`,
            backgroundSize: "cover",
            borderRadius: 12,
            padding: theme.spacing(1),
        },
        title: {
            textAlign: "center",
            fontWeight: 600,
            fontSize: "1.5rem",
            lineHeight: "1.5rem",
        },
        description: {
            textAlign: "center",
            fontSize: "0.875rem",
            lineHeight: "1.2rem",
        },
    });
});

interface IProps {
    tile: ITile;
    className?: string;
}

export default function TileDescription(props: IProps) {
    const { tile, className = "" } = props;
    const classes = useStyles();
    const theme = useTheme();

    const { name, description, button } = tile;

    const { hoverColour } = button;

    const highlightedStyles =
        typeof hoverColour === "undefined"
            ? undefined
            : { backgroundColor: hoverColour, color: theme.palette.common.white };

    return (
        <div className={clsx(className, classes.root)}>
            <Typography variant="h5" className={classes.title}>
                {name}
            </Typography>
            <ResponsiveSpacer multiplier={0.3} />
            <Typography variant="body2" className={classes.description}>
                {description}
            </Typography>
            <ResponsiveSpacer multiplier={0.5} />
            <div style={{ display: "flex", justifyContent: "center" }}>
                <SameTabLinkButton
                    href={button.href}
                    variant="transparent"
                    highlightedStyles={highlightedStyles}
                    style={{
                        pointerEvents: "all",
                    }}
                >
                    {button.text}
                </SameTabLinkButton>
            </div>
        </div>
    );
}
