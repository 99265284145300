import React, { useContext } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import ConfigContext from "contexts/ConfigContext";
import IRating from "models/IRating";
import NewTabLink from "components/links/NewTabLink";
import esrbImageSrc from "assets/images/ratings/esrb.png"
import { hoverOpacity } from "theme";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            "&:hover": {
                opacity: hoverOpacity
            }
        },
    });
});

interface IProps {}

export default function Rating(props: IProps) {
    const classes = useStyles();
    const config = useContext(ConfigContext);

    if (typeof config === "undefined") return null;

    const { supportedRatings, country } = config;
    const rating = getRatingForCountry(supportedRatings, country);

    return (
        <NewTabLink href={rating.href} className={classes.root}>
            <img src={rating.imgSrc} alt={rating.description} />
        </NewTabLink>
    );
}

function getRatingForCountry(ratings: IRating[], country: string): IRating {
    let ratingForCountry: IRating | undefined = undefined;

    for (let index = 0; index < ratings.length; index++) {
        const rating = ratings[index];

        const matchedCountries = rating.countries.find((c) => c.code === country);
        let isRatingForCountry = typeof matchedCountries !== "undefined";

        if (isRatingForCountry) {
            ratingForCountry = rating;
            break;
        }
    }

    const defaultRating: IRating = {
        countries: [],
        imgSrc: esrbImageSrc,
        description: "ESRB Logo",
        href: "https://www.esrb.org/",
        name: "ESRB",
    };

    return typeof ratingForCountry === "undefined" ? defaultRating : ratingForCountry;
}
