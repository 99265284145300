import React from "react";
import OmitType from "customTypes/OmitType";
import AosWrapper, { IAosWrapperProps, FlipAnimation } from "./AosWrapper";
import { getRandomElement } from "utils/arrayUtils";

interface IProps extends OmitType<IAosWrapperProps, "animation"> {}

export default function RandomFlip(props: IProps) {
    const animations: FlipAnimation[] = ["flip-up", "flip-down", "flip-left", "flip-right"];

    return <AosWrapper animation={getRandomElement(animations)} {...props} />;
}
