import { createMuiTheme, Theme } from "@material-ui/core/styles";

const white = "#F9F9F9";
const black = "#1C1C1C";

export const transition = {
    delay: "0s",
    duration: "0.15s",
    property: "all",
    timingFunction: "ease-in-out",
};

export const cssImportant = " !important";
export const transparentColour = "transparent";

export const hoverFadeAmount = 0.5
export const hoverOpacity = 0.9

export const fonts = {
    primary: "montserrat",
    secondary: "roboto",
};

const theme: Theme = createMuiTheme({
    palette: {
        primary: {
            main: "#012C3C",
        },
        secondary: {
            main: "#CF0B21",
        },
        common: {
            white: white,
            black: black,
        },
        background: {
            default: "#262626",
        },
    },
    typography: {
        fontFamily: fonts.primary,
    },
});

export const layoutConsts = {
    siteContentMaxWidth: 1500,
};

export default theme;
