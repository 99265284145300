import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { IPostContent } from "services/getCuratorContent";
import Typography from "@material-ui/core/Typography";

//@ts-ignore
import { Player, ControlBar, BigPlayButton } from "video-react";
import "assets/css/video-react.css";
import Spacer from "components/Spacer";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            "& img": {
                width: "100%",
            },
            "& video": {
                width: "100%",
            },
        },
        text: {
            fontSize: "1rem",
            lineHeight: "1.4",
        },
    });
});

interface IProps {
    content: IPostContent;
}

export default function PostContent(props: IProps) {
    const classes = useStyles();
    const { content } = props;

    const { text, image, video } = content;

    return (
        <div className={classes.root}>
            {typeof video === "undefined" ? (
                typeof image === "undefined" ? null : (
                    <>
                        <img alt={image.src} src={image.src} />
                        <Spacer size={2} />
                    </>
                )
            ) : (
                <>
                    <Player playsInline poster={image?.src} src={video.src} loop={true}>
                        <BigPlayButton position="center" />
                        <ControlBar disableCompletely={true}></ControlBar>
                    </Player>
                    <Spacer size={2} />
                </>
            )}
            <Typography variant="h6" className={classes.text}>
                {text}
            </Typography>
        </div>
    );
}
