const links = {
    legal: {
        termsAndConditions: "https://www.ea.com/terms-of-service.",
        privacyPolicy: "https://www.ea.com/en-gb/legal/privacy-policy",
        contactUs: "https://www.codemasters.com/contact-us/",
    },
    socialMedia: {
        twitter: "https://twitter.com/dirtgame",
        facebook: "https://www.facebook.com/dirtgame",
        youtube: "https://www.youtube.com/dirtgame",
        instagram: "https://www.instagram.com/dirtgame/",
    },
};

export default links;
