import React, { useContext } from "react";
import ConfigContext from "contexts/ConfigContext";
import { useHistory } from "react-router-dom";
import assetLocations from "assetLocations";
import StyledSelect from "components/inputs/StyledSelect";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

interface IProps {}

export default function LanguageSelector(props: IProps) {
    let history = useHistory();
    const config = useContext(ConfigContext);

    const handleLanguageChange = (country: string) => {
        history.push(country);
    };

    const getFlagSrc = (country: string) => {
        //@ts-ignore
        let iconSrc = assetLocations.flags[country];
        if (typeof iconSrc === "undefined") iconSrc = assetLocations.flags.world;

        return iconSrc;
    };

    if (typeof config === "undefined") return null;

    const { supportedCountries, country } = config;

    return (
        <div>
            <StyledSelect
                selectProps={{
                    value: country,
                    onChange: (
                        event: React.ChangeEvent<{
                            name?: string | undefined;
                            value: unknown;
                        }>
                    ) => handleLanguageChange(event.target.value as string),
                }}
            >
                {supportedCountries.map((c: string) => {
                    return (
                        <MenuItem value={c} key={c}>
                            <ListItemIcon>
                                <img src={getFlagSrc(c)} alt={c} />
                            </ListItemIcon>
                            <ListItemText primary={c.toUpperCase()} />
                        </MenuItem>
                    );
                })}
            </StyledSelect>

            <div style={{ display: "none" }}>
                {/* The following makes sure the country images are loaded before the menu is opened */}
                {supportedCountries.map((c: string) => {
                    return <img src={getFlagSrc(c)} alt={c} />;
                })}
            </div>
        </div>
    );
}
