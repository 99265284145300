import React from "react";
import TopBannerBackground from "./backgrounds/TopBannerBackground";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import dirtLogo from "assets/images/logos/dirt.png";

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 5,
        },
    })
);

export default function TopBanner() {
    const classes = useStyles();

    return (
        <TopBannerBackground className={classes.root}>
            <img src={dirtLogo} alt="Dirt Logo" style={{ width: 120 }} />
        </TopBannerBackground>
    );
}
