import React, { useContext } from "react";
import ConfigContext from "contexts/ConfigContext";
import Content from "./Content";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: 500,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        loader: {
            color: theme.palette.common.white,
        },
    })
);

export default function GameDisplay() {
    const classes = useStyles();
    const config = useContext(ConfigContext);

    return (
        <div className={classes.root}>
            {typeof config === "undefined" ? (
                <div className={classes.loader}>
                    <CircularProgress color="inherit" size={60} />
                </div>
            ) : (
                <Content country={config.country} />
            )}
        </div>
    );
}
