import React, { HTMLAttributes } from "react";
import BackgroundImage from "./BackgroundImage";
import backgroundImageSrc from "assets/images/backgrounds/bg_socialwall.png"

interface IProps extends HTMLAttributes<HTMLDivElement> {}

export default function SocialWallBackground(props: IProps) {
    return (
        <BackgroundImage
            backgroundStyle={{
                imageUrl: backgroundImageSrc,
                backgroundSize: "100%",
                backgroundRepeat: "no-repeat",
                flex: 1,
            }}
            {...props}
        />
    );
}
