import React, { ReactNode } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import TopBanner from "components/TopBanner";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            minHeight: "100vh",
            backgroundColor: theme.palette.background.default,
            color: theme.palette.common.white,
        },
        content: {
            flex: "1 1 auto",
            display: "flex",
            position: "relative",
        },
    })
);

interface IStickyFooterProps extends React.HTMLAttributes<HTMLDivElement> {
    footer: ReactNode;
}

export default function StickyFooter(props: IStickyFooterProps) {
    const classes = useStyles();
    const { children, footer, className, ...rest } = props;

    return (
        <main className={clsx(className, classes.root)} {...rest}>
            <TopBanner />
            <div className={classes.content}>{children}</div>
            {footer}
        </main>
    );
}
